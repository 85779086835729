<template>
    <div>
        <Hero
            :title="$t('site.title')"
            :lead="$t('homepage.hero.lead')"
            :description="/(zh|ko)/.test($i18n.locale) ? $t('homepage.hero.description') : null"
            :primary-button="{ label: $t('generic.contact_us'), url: localePath('/contact') }"
            :secondary-button="{ label: $t('solutions.title'), url: localePath('/services') }"
            :illustration="require('~/assets/images/illustrations/b-mask_banyai.png')"
            :decoration="require('~/assets/images/illustrations/decoration-06.svg')"
        />

        {{/* Services */}}
        <div v-if="services && services.length" class="relative pt-16 sm:pt-4 lg:pt-12 xl:pt-24">
            <SectionServices :services="services" />
        </div>

        {{/* Indian workforce */}}
        <div class="relative pb-12 mt-12">
            <div class="absolute bottom-0 left-0 w-full h-2/3 bg-white z-0"></div>
            <div class="container relative z-10 grid grid-cols-1 lg:grid-cols-2 gap-8 xl:gap-12">
                <FeaturedWorkforceCard
                    :img-src="require('~/assets/images/illustrations/indian-workforce/b-mask_indian-guy.png')"
                    :title="$t('indian_workforce.featured_card.title')"
                    :description="$t('indian_workforce.featured_card.description')"
                    :url="localePath('/indian-workforce')"
                    :bg-decorator="require('~/assets/images/illustrations/logo-as-banner-bg-shape_magenta.svg')"
                    bg-gradient-colors="#A60D57, #EA2A87"
                />
                <FeaturedWorkforceCard
                    :img-src="require('~/assets/images/illustrations/filipino-workforce/b-mask_filipino-guy.png')"
                    :title="$t('filipino_workforce.featured_card.title')"
                    :description="$t('filipino_workforce.featured_card.description')"
                    :url="localePath('/filipino-workforce')"
                    :bg-decorator="require('~/assets/images/illustrations/logo-as-banner-bg-shape_blue.svg')"
                    bg-gradient-colors="#2E2AFF, #5451EA"
                />
            </div>
        </div>

        {{/* Why choose us */}}
        <div class="relative pt-16 lg:pt-20 pb-16 bg-white text-black">
            <div class="container">
                <SectionWrapper :title="$t('introduction.title')">
                    <FeatureCard
                        :title="$t('introduction.section_1.title')"
                        :description="$t('introduction.section_1.description')"
                        :illustration="require('~/assets/images/illustrations/stock-01.jpg')"
                        rounded-t-r
                    />

                    <FeatureCard
                        :title="$t('introduction.section_2.title')"
                        :description="$t('introduction.section_2.description')"
                        :illustration="require('~/assets/images/illustrations/stock-02.jpg')"
                        reversed
                        rounded-t-r
                        rounded-b-l
                    />

                    <FeatureCard
                        :title="$t('introduction.section_3.title')"
                        :description="$t('introduction.section_3.description')"
                        :illustration="require('~/assets/images/illustrations/stock-03.jpg')"
                        rounded-b-r
                    />
                </SectionWrapper>
                <div class="flex justify-center flex-wrap px-2 sm:mt-10">
                    <NuxtLink :to="localePath('/about-us')" class="p-button p-button-secondary p-button-outlined m-2">
                        <span class="p-button-label">{{ $t('about_us.title') }}</span>
                    </NuxtLink>
                </div>
            </div>
        </div>

        {{/* News */}}
        <div
            v-if="featuredArticles && featuredArticles.length"
            class="relative px-0 pb-10 sm:pb-16 sm:pt-20 lg:pt-24 bg-white text-black relative"
        >
            <div class="sm:container">
                <SectionWrapper
                    :title="$t('news.lead')"
                    :link="{ path: localePath('/news'), label: $t('news.more') }"
                    is-carousel-wrapper
                    theme-light
                >
                    <ArticleCarousel :articles="featuredArticles" />
                </SectionWrapper>
            </div>
        </div>

        {{/* Partners */}}
        <div v-if="partners && partners.length" class="py-8 lg:py-10">
            <div class="container">
                <SectionPartners :partners="partners" :title="$t('generic.partners')" />
            </div>
        </div>

        {{/* Testimonials */}}
        <SectionTestimonials v-if="testimonials && testimonials.length" :testimonials="testimonials" />

        {{/* Typical HR problems */}}
        <div class="pt-10 lg:pt-20 pb-20 lg:pb-24 mt-8 overflow-hidden bg-black">
            <div class="relative container">
                <ContactForm hide-subtitle />
            </div>
        </div>
    </div>
</template>

<script>
import Hero from '@/components/Heroes/Hero';
import SectionWrapper from '@/components/UI/SectionWrapper';
import SectionPartners from '@/components/Sections/SectionPartners/SectionPartners';
import SectionTestimonials from '@/components/Sections/SectionTestimonials/SectionTestimonials';
import SectionServices from '@/components/Sections/SectionServices/SectionServices';
import ArticleCarousel from '@/components/Carousels/ArticleCarousel';
import FeatureCard from '@/components/Cards/FeatureCard/FeatureCard';
import ContactForm from '@/components/Forms/ContactForm';
import FeaturedWorkforceCard from '@/components/Cards/FeaturedWorkforceCard.vue';

export default {
    name: 'Index',
    components: {
        FeaturedWorkforceCard,
        Hero,
        SectionWrapper,
        FeatureCard,
        SectionPartners,
        SectionTestimonials,
        ArticleCarousel,
        ContactForm,
        SectionServices,
    },

    async asyncData({ $axios }) {
        let services = null;
        let featuredArticles = null;
        let partners = null;
        let testimonials = null;

        try {
            await Promise.all([
                $axios.$get('/service?is_highlighted=1'),
                $axios.$get('/article?highlighted=1&limit=3'),
                $axios.$get('/partner'),
                $axios.$get('/testimonial?type=1'),
            ])
                .then(([servicesRes, featuredArticlesRes, partnersRes, testimonialRes]) => {
                    if (servicesRes.success === 1) {
                        services = servicesRes.data;
                    }

                    if (featuredArticlesRes.success === 1) {
                        featuredArticles = featuredArticlesRes.data;
                    }
                    if (partnersRes.success === 1) {
                        partners = partnersRes.data;
                    }

                    if (testimonialRes.success === 1) {
                        testimonials = testimonialRes.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }

        return { services, featuredArticles, partners, testimonials };
    },
};
</script>

<style scoped></style>
