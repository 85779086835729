<template>
    <span class="text-xs max-w-sm">
        This site is protected by reCAPTCHA and the Google
        <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            class="font-bold desktop:hover:underline"
        >
            Privacy Policy
        </a>
        and
        <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            class="font-bold desktop:hover:underline"
        >
            Terms of Service
        </a>
        apply.
    </span>
</template>

<script>
export default {
    name: 'RecaptchaDisclaimer',
};
</script>

<style scoped></style>
