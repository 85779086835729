<template>
    <div
        class="relative px-5 pt-12 pb-12 md:pb-16 xl:pb-20 md:px-0 text-white diagonally-rounded overflow-hidden"
        :style="`background: linear-gradient(to right, ${bgGradientColors}); box-shadow: 0 4px 80px rgba(35, 18, 91, 0.5)`"
    >
        <div
            class="absolute top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat"
            :style="`background-image: url('${bgDecorator}')`"
        ></div>
        <div class="relative grid grid-cols-12 md:gap-5 z-10 lg:h-full" style="grid-template-rows: auto 1fr">
            {{/* Guy image (left side) */}}
            <div class="col-span-12 md:col-span-4 md:col-start-2 hidden md:block lg:min-h-[280px] xl:min-h-[350px]">
                <div class="relative max-w-[350px]">
                    <div
                        class="w-full aspect-h-11 aspect-w-7 bg-center bg-contain bg-no-repeat z-10"
                        :style="`background-image: url(${imgSrc})`"
                    ></div>

                    {{/* many yellow dots in a rectangle decorator */}}
                    <div
                        class="absolute w-44 h-32 -bottom-11 -left-5 bg-left-bottom bg-contain bg-no-repeat z-0"
                        :style="`background-image: url(${require('~/assets/images/illustrations/decoration-05.svg')})`"
                    ></div>
                </div>
            </div>

            {{/* Title (right side) */}}
            <div class="col-span-12 md:col-span-5 md:col-start-7 flex flex-col justify-center lg:justify-start">
                <span class="opacity-50 font-bold mb-4 uppercase">{{ $t('our_services.flagship_service') }}</span>
                <div class="font-Abhaya-Libre font-bold text-2xl md:text-4xl lg:text-3xl xl:text-4xl decor-underline">
                    {{ title }}
                </div>
            </div>

            {{/* Description & cta (bottom) */}}
            <div class="col-span-12 md:px-12 lg:px-8 mt-6 md:mt-16 lg:mt-0 flex flex-col justify-between">
                <div class="" v-html="description"></div>
                <div class="mt-12">
                    <NuxtLink :to="url" class="p-button p-button-secondary">
                        <span class="p-button-label">{{ $t('generic.get_to_know_our_service') }}</span>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeaturedWorkforceCard',
    props: {
        imgSrc: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        bgDecorator: {
            type: String,
            required: true,
        },
        bgGradientColors: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
