<template>
    <NuxtLink
        :to="url"
        class="
            card-wrapper
            w-full
            h-full
            transition-all
            relative
            pl-4
            pr-6
            pt-5
            sm:pt-9
            pb-5
            sm:pb-12
            overflow-hidden
            rounded-sm
        "
        :class="[
            $device.isMobileOrTablet
                ? 'rounded-tr-40 sm:rounded-tr-80 rounded-bl-40 sm:rounded-bl-80'
                : 'desktop:hover:rounded-tr-80 desktop:hover:rounded-bl-80',
        ]"
    >
        <div
            class="background absolute top-0 left-0 w-full h-full purple-gradient-bg"
            :class="[
                $device.isMobileOrTablet ? 'opacity-100' : 'opacity-0 desktop:hover:opacity-100',
                $device.isSafari ? 'rounded-tr-40 sm:rounded-tr-80 rounded-bl-40 sm:rounded-bl-80' : '',
            ]"
        ></div>

        <div class="relative flex flex-col sm:justify-between h-full z-10 pointer-events-none">
            <div
                class="
                    pictogram
                    transition-all
                    relative
                    w-20
                    h-20
                    min-w-20 min-h-20
                    rounded-full
                    border
                    mb-5
                    sm:mx-auto
                    bg-center bg-no-repeat
                "
                :style="`background-size: 2rem 2rem; background-image: url(${pictogram})`"
            ></div>

            <div class="flex flex-col flex-grow justify-between sm:items-center">
                <div class="title text-[22px] mb-2 sm:mb-8 sm:text-center">{{ title }}</div>

                <span
                    class="next-button sm:mx-auto rounded-full py-2 pl-4 pr-5 transition-colors"
                    :class="$device.isMobileOrTablet ? 'pl-0' : ''"
                >
                    <span class="text-sm sm:text-base font-bold mr-1"> {{ $t('generic.read_more') }} </span>
                    <span class="pi pi-arrow-right transition-transform text-sm"></span>
                </span>
            </div>
        </div>
    </NuxtLink>
</template>

<script>
export default {
    name: 'ServiceCard',
    props: {
        url: {
            type: [String, Object],
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        pictogram: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.card-wrapper {
    .background {
        transition: opacity 0.2s;
        z-index: 0;
    }
    .pictogram {
        border-color: rgba(255, 255, 255, 0.5);
    }
    &:hover {
        .pictogram {
            border-color: #fff;
        }
        .next-button {
            background-color: rgba(255, 255, 255, 0.2);
        }
        .pi-arrow-right {
            transition-delay: 0.05s;
            transform: translateX(3px);
        }
    }
}
</style>
