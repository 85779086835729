var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.isModal)?_c('span',{staticClass:"text-purple-200 font-Montserrat font-bold uppercase mb-10"},[_vm._v("\n        "+_vm._s(_vm.$t('contact.send_a_message'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-12 gap-5",class:_vm.displayAsCard ? 'bg-gray-900 py-12 sm:pt-16 sm:pb-20 px-6 sm:px-12 diagonally-rounded ' : ''},[(!_vm.isModal)?_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('h2',{staticClass:"title decor-underline decor-underline--magenta mb-6 lg:mb-10",class:_vm.isOnContactEndpage ? 'title-base' : 'title-lg'},[_vm._v("\n                "+_vm._s(_vm.local_title)+"\n            ")]),_vm._v(" "),(!_vm.hideSubtitle)?_c('p',{staticClass:"mb-4"},[_vm._v("\n                "+_vm._s(_vm.local_subtitle)+"\n            ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative col-span-12",class:!_vm.isModal && 'lg:col-span-6 lg:col-start-7'},[_c('div',{class:[
                    !_vm.isThankYouMessageVisible ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none',
                    'transition-opacity' ]},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('form',{staticClass:"w-full",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"grid grid-cols-6 gap-5"},[(_vm.$i18n.locale !== 'hu')?_c('div',{staticClass:"col-span-6 sm:col-span-3"},[_c('ValidationProvider',{attrs:{"vid":"first_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputText',{class:{ 'p-invalid': errors.length },attrs:{"name":"first_name","placeholder":_vm.$t('contact.first_name'),"type":"text"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}],null,false,3377781258)})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-span-6 sm:col-span-3"},[_c('ValidationProvider',{attrs:{"vid":"last_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputText',{class:{ 'p-invalid': errors.length },attrs:{"name":"last_name","placeholder":_vm.$t('contact.last_name'),"type":"text"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}])})],1),_vm._v(" "),(_vm.$i18n.locale === 'hu')?_c('div',{staticClass:"col-span-6 sm:col-span-3"},[_c('ValidationProvider',{attrs:{"vid":"first_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputText',{class:{ 'p-invalid': errors.length },attrs:{"name":"first_name","placeholder":_vm.$t('contact.first_name'),"type":"text"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}],null,false,3377781258)})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-span-6"},[_c('ValidationProvider',{attrs:{"vid":"email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputText',{class:{ 'p-invalid': errors.length },attrs:{"name":"email","placeholder":_vm.$t('contact.email_address'),"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}])})],1),_vm._v(" "),(!_vm.b2c)?_c('div',{staticClass:"col-span-6 sm:col-span-3"},[_c('ValidationProvider',{attrs:{"vid":"company_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputText',{class:{ 'p-invalid': errors.length },attrs:{"name":"company_name","placeholder":_vm.$t('contact.company_name'),"type":"text"},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}],null,false,3690953431)})],1):_vm._e(),_vm._v(" "),(!_vm.b2c)?_c('div',{staticClass:"col-span-6 sm:col-span-3"},[_c('ValidationProvider',{attrs:{"vid":"position","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputText',{class:{ 'p-invalid': errors.length },attrs:{"name":"position","placeholder":_vm.$t('contact.position'),"type":"text"},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}],null,false,1368670993)})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-span-6"},[_c('ValidationProvider',{attrs:{"vid":"message","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('Textarea',{class:{ 'p-invalid': errors.length },attrs:{"name":"message","rows":"5","placeholder":_vm.$t('contact.message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_vm._v(" "),_c('InputError',{staticClass:"ml-5",attrs:{"errors":errors}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"col-span-6 sm:col-span-4",class:_vm.isModal && 'text-black'},[_c('ValidationProvider',{attrs:{"vid":"privacy","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"p-field flex items-start"},[_c('Checkbox',{attrs:{"id":"privacy_policy_cbx","binary":true},model:{value:(_vm.accept_privacy_policy),callback:function ($$v) {_vm.accept_privacy_policy=$$v},expression:"accept_privacy_policy"}}),_vm._v(" "),_c('label',{attrs:{"for":"privacy_policy_cbx"}},[_vm._v("\n                                            "+_vm._s(_vm.$t('contact.accept_policy.msg1'))+"\n                                            "),_c('a',{staticClass:"font-bold desktop:hover:underline",attrs:{"href":_vm.localePath({ name: 'privacy-policy' }),"target":"_blank"}},[_vm._v("\n                                                "+_vm._s(_vm.$t('contact.accept_policy.msg2'))+"\n                                            ")])])],1),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('RecaptchaDisclaimer')],1)],1),_vm._v(" "),_c('div',{staticClass:"\n                                    col-span-3 col-start-4\n                                    sm:col-span-2 sm:col-start-5\n                                    mt-6\n                                    sm:mt-0\n                                    text-right\n                                    flex\n                                    items-center\n                                "},[_c('Button',{staticClass:"w-full flex justify-center",staticStyle:{"color":"white"},attrs:{"type":"submit","label":_vm.$t('contact.send')}})],1)])])])],1)])]),_vm._v(" "),_c('div',{staticClass:"transition-opacity absolute top-0 left-0 w-full h-full bg-white flex flex-col justify-center",class:[
            _vm.isThankYouMessageVisible ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none',
            _vm.isModal && 'text-black' ]},[_c('p',{staticClass:"font-Abhaya-Libre font-bold text-2xl mb-5"},[_vm._v(_vm._s(_vm.$t('contact.message_sent_feedback.msg1')))]),_vm._v(" "),_c('p',{staticClass:"text-15 mb-12"},[_vm._v("\n            "+_vm._s(_vm.$t('contact.message_sent_feedback.msg2'))+"\n        ")]),_vm._v(" "),_c('Button',{class:[
                _vm.isOnContactEndpage ? 'p-button-dark p-button-secondary w-full xs:w-auto' : '',
                _vm.isModal && 'p-button-primary w-fit' ],attrs:{"label":_vm.$t('generic.back')},on:{"click":_vm.closeThankYouScreen}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }