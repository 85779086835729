<template>
    <div class="container px-0">
        <SectionWrapper
            :title="$t('solutions.title')"
            :link="
                getRouteBaseName() !== 'services'
                    ? { path: localePath('/services'), label: $t('generic.read_more') }
                    : null
            "
            is-carousel-wrapper
        >
            <ServicesCarousel :services="services" />
        </SectionWrapper>
    </div>
</template>

<script>
import SectionWrapper from '@/components/UI/SectionWrapper';
import ServicesCarousel from '@/components/Carousels/ServicesCarousel';

export default {
    name: 'SectionServices',
    components: {
        SectionWrapper,
        ServicesCarousel,
    },
    props: {
        services: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped></style>
