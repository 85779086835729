<template>
    <div class="relative">
        <span v-if="isModal" class="text-purple-200 font-Montserrat font-bold uppercase mb-10">
            {{ $t('contact.send_a_message') }}
        </span>

        <div
            class="grid grid-cols-12 gap-5"
            :class="displayAsCard ? 'bg-gray-900 py-12 sm:pt-16 sm:pb-20 px-6 sm:px-12 diagonally-rounded ' : ''"
        >
            <div v-if="!isModal" class="col-span-12 lg:col-span-5">
                <h2
                    class="title decor-underline decor-underline--magenta mb-6 lg:mb-10"
                    :class="isOnContactEndpage ? 'title-base' : 'title-lg'"
                >
                    {{ local_title }}
                </h2>
                <p v-if="!hideSubtitle" class="mb-4">
                    {{ local_subtitle }}
                </p>
            </div>
            <div class="relative col-span-12" :class="!isModal && 'lg:col-span-6 lg:col-start-7'">
                <div
                    :class="[
                        !isThankYouMessageVisible ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none',
                        'transition-opacity',
                    ]"
                >
                    <ValidationObserver ref="form" slim>
                        <form novalidate class="w-full" @submit.prevent="onSubmit">
                            <div class="grid grid-cols-6 gap-5">
                                <div v-if="$i18n.locale !== 'hu'" class="col-span-6 sm:col-span-3">
                                    <ValidationProvider v-slot="{ errors }" vid="first_name" tag="div">
                                        <InputText
                                            v-model="first_name"
                                            name="first_name"
                                            :placeholder="$t('contact.first_name')"
                                            :class="{ 'p-invalid': errors.length }"
                                            type="text"
                                        />
                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>
                                <div class="col-span-6 sm:col-span-3">
                                    <ValidationProvider v-slot="{ errors }" vid="last_name" tag="div">
                                        <InputText
                                            v-model="last_name"
                                            name="last_name"
                                            :placeholder="$t('contact.last_name')"
                                            :class="{ 'p-invalid': errors.length }"
                                            type="text"
                                        />
                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>
                                <div v-if="$i18n.locale === 'hu'" class="col-span-6 sm:col-span-3">
                                    <ValidationProvider v-slot="{ errors }" vid="first_name" tag="div">
                                        <InputText
                                            v-model="first_name"
                                            name="first_name"
                                            :placeholder="$t('contact.first_name')"
                                            :class="{ 'p-invalid': errors.length }"
                                            type="text"
                                        />
                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>
                                <div class="col-span-6">
                                    <ValidationProvider v-slot="{ errors }" vid="email" tag="div">
                                        <InputText
                                            v-model="email"
                                            name="email"
                                            :placeholder="$t('contact.email_address')"
                                            :class="{ 'p-invalid': errors.length }"
                                            type="email"
                                        />
                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>
                                <div v-if="!b2c" class="col-span-6 sm:col-span-3">
                                    <ValidationProvider v-slot="{ errors }" vid="company_name" tag="div">
                                        <InputText
                                            v-model="company_name"
                                            name="company_name"
                                            :placeholder="$t('contact.company_name')"
                                            :class="{ 'p-invalid': errors.length }"
                                            type="text"
                                        />
                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>
                                <div v-if="!b2c" class="col-span-6 sm:col-span-3">
                                    <ValidationProvider v-slot="{ errors }" vid="position" tag="div">
                                        <InputText
                                            v-model="position"
                                            name="position"
                                            :placeholder="$t('contact.position')"
                                            :class="{ 'p-invalid': errors.length }"
                                            type="text"
                                        />
                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>

                                <div class="col-span-6">
                                    <ValidationProvider v-slot="{ errors }" vid="message" tag="div">
                                        <Textarea
                                            v-model="message"
                                            name="message"
                                            rows="5"
                                            :class="{ 'p-invalid': errors.length }"
                                            :placeholder="$t('contact.message')"
                                        />

                                        <InputError :errors="errors" class="ml-5" />
                                    </ValidationProvider>
                                </div>

                                <div class="col-span-6 sm:col-span-4" :class="isModal && 'text-black'">
                                    <ValidationProvider v-slot="{ errors }" vid="privacy" tag="div">
                                        <div class="p-field flex items-start">
                                            <Checkbox
                                                id="privacy_policy_cbx"
                                                v-model="accept_privacy_policy"
                                                :binary="true"
                                            />
                                            <label for="privacy_policy_cbx">
                                                {{ $t('contact.accept_policy.msg1') }}
                                                <a
                                                    :href="localePath({ name: 'privacy-policy' })"
                                                    target="_blank"
                                                    class="font-bold desktop:hover:underline"
                                                >
                                                    {{ $t('contact.accept_policy.msg2') }}
                                                </a>
                                            </label>
                                        </div>
                                        <InputError :errors="errors" />
                                    </ValidationProvider>

                                    <div class="mt-4">
                                        <RecaptchaDisclaimer />
                                    </div>
                                </div>
                                <div
                                    class="
                                        col-span-3 col-start-4
                                        sm:col-span-2 sm:col-start-5
                                        mt-6
                                        sm:mt-0
                                        text-right
                                        flex
                                        items-center
                                    "
                                >
                                    <Button
                                        type="submit"
                                        :label="$t('contact.send')"
                                        class="w-full flex justify-center"
                                        style="color: white"
                                    />
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div
            class="transition-opacity absolute top-0 left-0 w-full h-full bg-white flex flex-col justify-center"
            :class="[
                isThankYouMessageVisible ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none',
                isModal && 'text-black',
            ]"
        >
            <p class="font-Abhaya-Libre font-bold text-2xl mb-5">{{ $t('contact.message_sent_feedback.msg1') }}</p>
            <p class="text-15 mb-12">
                {{ $t('contact.message_sent_feedback.msg2') }}
            </p>
            <Button
                :label="$t('generic.back')"
                :class="[
                    isOnContactEndpage ? 'p-button-dark p-button-secondary w-full xs:w-auto' : '',
                    isModal && 'p-button-primary w-fit',
                ]"
                @click="closeThankYouScreen"
            />
        </div>
    </div>
</template>

<script>
import RecaptchaDisclaimer from '@/components/UI/RecaptchaDisclaimer';

export default {
    name: 'ContactForm',
    components: {
        RecaptchaDisclaimer,
    },
    props: {
        b2c: {
            type: Boolean,
            required: false,
            default: false,
        },
        displayAsCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideSubtitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        subtitle: {
            type: String,
            required: false,
            default: '',
        },
        isOnContactEndpage: {
            type: Boolean,
            required: false,
            default: false,
        },
        isModal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            company_name: '',
            position: '',
            subject: '',
            message: '',
            accept_privacy_policy: false,
            local_title: '',
            local_subtitle: '',
            isThankYouMessageVisible: false,
        };
    },
    async mounted() {
        if (!this.title) {
            this.local_title = this.$t('generic.contact_us');
        } else {
            this.local_title = this.title;
        }

        if (!this.subtitle) {
            this.local_subtitle = this.$t('contact.companies.description');
        } else {
            this.local_subtitle = this.subtitle;
        }

        await this.$recaptcha.init();
    },
    beforeDestroy() {
        this.$recaptcha.destroy();
    },
    methods: {
        async onSubmit() {
            try {
                const token = await this.$recaptcha.execute('contact');

                await this.$axios
                    .$post(
                        '/recaptcha/validate/contact',
                        {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            email: this.email,
                            company_name: this.company_name,
                            position: this.position,
                            message: this.message,
                            privacy: this.accept_privacy_policy,
                            type: this.b2c ? 2 : 1,
                        },
                        {
                            baseURL: '',
                            headers: {
                                'base-url': this.$axios.defaults.baseURL,
                                'recaptcha-token': token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.success === 1) {
                            this.isThankYouMessageVisible = true;
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                    });

                await this.$recaptcha.reset();
            } catch (err) {
                console.log(err);
            }
        },
        closeThankYouScreen() {
            this.first_name = '';
            this.last_name = '';
            this.email = '';
            this.company_name = '';
            this.position = '';
            this.message = '';
            this.accept_privacy_policy = false;

            this.isThankYouMessageVisible = false;
        },
    },
};
</script>

<style>
.w-fit {
    width: fit-content;
}
</style>
